import React,{useState} from 'react'
import logo from '../assets/bb.png'


function Login() {
   const[input,setInput]=useState({
    number:''
   })
   console.log(input,"number")

    const handleChange=(e)=>{
       setInput({
        ...input,
        [e.target.name]:e.target.value
       })
    }

    const handleSubmit=(e)=>{
        e.preventDefault()

    }
  return (
    <div>
      <div className=" bg-gradient-to-br from-purple-400 to-pink-200 h-screen flex flex-col justify-center items-center">
    <div className="bg-white border-2 border-purple-300 rounded-lg shadow-lg p-8 mx-8 shadow-purple-300">
        <img src={logo} alt="bubble tv" className='lg:w-[270px] lg:h-[160px]'/>
        <h1 className="lg:text-xl font-bold text-center text-purple-900 mb-8">LOGIN WITH YOUR NUMBER</h1>
        <form onSubmit={handleSubmit}
         className="space-y-6">
            <div>
                <label className="block text-purple-900 font-bold mb-2" for="number">
                    Enter Number
                </label>
                <input onChange={handleChange}
                id="number" 
                name="number"
                value={input.number}
                type="number" 
                placeholder='Enter Registed Number'
                className="w-full px-4 py-2 rounded-lg border-2 border-purple-900 hover:border-purple-600" />
            </div>
            
            <div>
                <button type="submit"
                className="w-full bg-purple-700 hover:bg-purple-400 text-white font-bold py-2 px-4 rounded-lg">
                    Log In
                </button>
            </div>
        </form>
    </div>
</div>
    </div>
  )
}

export default Login
